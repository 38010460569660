@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.NavbarItems {
    /* background-color: rgb(153, 153, 153); */
    background: transparent;
    opacity: 10;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    /*  border-bottom: 0.1px solid #fff; */
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    z-index: 999;
    position: absolute;
    width: 99vw;
}

.navbar-logo-transparent {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    padding-top: 5px;
    padding: 4px;
    font-family: "Roboto", arial, sans-serif;
}
.navbar-logo {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    top: 10px;
    font-family: "Roboto", arial, sans-serif;
}
.navbar-logo > img {
    margin: 1rem;
}

h3 {
    background-color: #ffc000;
    color: #000;
}

.NavbarItems > h1 > img {
    margin-right: 0.8rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.login-btn {
    display: none;
}

.nav-menu > li > a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0.5rem 1rem;
}
.nav-menu a:hover {
    color: #ffc000;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

Button {
    background-color: #ffc000;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.menu-icon {
    display: none;
}

.fixed {
    scroll-behavior: smooth;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.3s;
    background-color: #fff;
    text-decoration: #000;
}

.fixed > .nav-menu > li > a {
    color: #000;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        justify-content: flex-start;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: rgb(255, 255, 255);
        left: 0;
        height: 100vh;
        opacity: 100%;
        transition: all 0.5s ease;
        z-index: 1;
        padding-top: 1rem;
    }

    .nav-menu > li {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-menu > li > a {
        color: #ffc000;
        text-decoration: none;
        padding: 0.8rem 3rem;
        margin-top: 2rem;
    }
    .nav-menu li:hover {
        color: #ffc000;
        transition: all 0.2s ease-out;
    }
    .nav-menu > li {
        padding: 0.5rem 4rem;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
    }
    .navbar-logo-transparent {
        color: rgb(0, 0, 0);
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-bars {
        color: rgb(0, 0, 0);
        font-size: 2rem;
        align-self: center;
    }
    .login-btn {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        border-radius: 4px;
        background: #ffc000;
        text-decoration: none;
        color: #000 !important;
        font-size: 1.5rem;
    }
    button {
        display: none;
    }
}
