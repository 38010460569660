@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");
.footer-container {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.9rem;
    background-color: #000;
    color: #edf6f9;
    font-family: "Ubuntu", sans-serif;
}
.footer-container > div {
    width: 30%;
}
.copyright-content,
.made-by {
    font-family: "Ubuntu", sans-serif;
}
.footer-socials {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    justify-content: space-evenly;
}
/* .footer-socials > div {
	margin-right: 20px;
} */
.footer-socials > div:hover {
    color: #f77f00;
    cursor: pointer;
    user-select: none;
}
.heart {
    color: red;
}
@media only screen and (max-width: 950px) {
    .footer-container {
        flex-direction: column;
        height: 150px;
    }
    .footer-container > div {
        width: 100%;
    }
    .footer-socials {
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .footer-socials > div {
        margin-right: 0px;
        color: #f77f00;
    }
}
