.profile-card-container {
    width: 15rem;
    height: 15rem;
    border-radius: 20px;
    background-color: "#CECCC7";
    margin: 1rem;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.profile-image,
.profile-image-img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.profile-info-container {
    width: 15rem;
    height: 15rem;
    background-image: linear-gradient(to bottom, transparent 0%, #001219 100%);
    position: relative;
    border-radius: 20px;
    top: -15rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
}
.profile-name,
.profile-domain {
    display: flex;
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
}
.profile-domain {
    font-weight: 200;
    font-size: 1rem;
    margin-bottom: 20px;
}
.profile-info {
    margin-bottom: 20px;
    display: none;
    opacity: 0;
    transition: opacity 2s linear;
}

.profile-info-container:hover > .profile-info {
    display: flex;
    opacity: 1;
}

.tabs {
    margin-top: 1rem;
}
